import { HttpService } from '@/shared/api/services/common/http.service';
import { UserService } from '@/shared/api/services/user';
import { IVideo, IVideoThumb, IVideoThumbsResponse } from '@/shared/api/services/neuropass/models/videoThumb.model';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { EConfigStorageKeys } from '@/shared/api/services/common/enums/EConfigStorageKeys';

export class NeuropassService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: NeuropassService;

    constructor() {
      super();
      NeuropassService.token = UserService.getToken();
      NeuropassService.headers = {
        Authorization: `bearer ${NeuropassService.token}`,
      };

      if (NeuropassService._instance) {
        return NeuropassService._instance;
      }

      NeuropassService._instance = this;
    }

    /* Получить список видеороликов для использования в создании пиксета */
    async getList(limit: number, page: number, { sort = 'processed DESC', filters = {} } = {}): Promise<IVideoThumbsResponse> {
      let url = 'video?';

      if (limit) {
        url = `${url}_limit=${limit}`;
      }

      if (page) {
        url = `${url}&_offset=${(page - 1) * limit}`;
      }

      if (sort) {
        url = `${url}&_order_by=${sort}`;
      }

      console.log(filters);

      if (filters) {
        Object.entries(filters).forEach(([key, value], index) => {
          index
            ? url = `${url} and ${key} == "${value}"`
            : url = `${url}&_filter=${key} == "${value}"`;
        });
      }

      return this.http(url, EHttpMethods.Get, this.getHeaders(), undefined, { endpoint: EConfigStorageKeys.apiEndpointNeuro });
    }

    async getSingleByGuid(guid: string): Promise<Record<'video', IVideo>> {
      const url = `video/${guid}`;

      return this.http(url, EHttpMethods.Get, this.getHeaders(), undefined, { endpoint: EConfigStorageKeys.apiEndpointNeuro });
    }

    async getSingleByMd5(md5: string): Promise<Record<'video', IVideo>> {
      const url = `video/md5/${md5}`;

      return this.http(url, EHttpMethods.Get, this.getHeaders(), undefined, { endpoint: EConfigStorageKeys.apiEndpointNeuro });
    }
}
