import { HttpService } from '@/shared/api/services/common/http.service';
import {
  IUserLoginResponse,
} from '@/shared/api/services/user/models/userApi.model';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { IUserDraft, IUserInfo, IUserLoginData } from '@/shared/models/user.model';
import { DEFAULT_PAGINATION } from '@/shared/api/services/common/constants/pagination.const';
import { IBasePaginatedResponse } from '@/shared/api/services/common/models/http.model';

/**
 * Сервис для работы с данными юзера
 * @module UserService
 */

export class UserService extends HttpService {
    private static token: string | null;

    private static userName: string | null;

    private static _instance: UserService;

    constructor() {
      super();
      UserService.token = UserService.getToken();
      UserService.userName = null;

      if (UserService._instance) {
        return UserService._instance;
      }

      UserService._instance = this;
    }

    /** Состояние авторизации пользователя
     * @returns {Boolean} true/false
     */
    public static isAuth(): boolean {
      return !!UserService.getToken();
    }

    /** Метод для получения токена юзера
     * @returns {String}
     */
    public static getToken(): string {
      const matches = document.cookie.match(new RegExp(
        `(?:^|; )${'token'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
      ));

      const safeMatches = Array.isArray(matches) && matches[1] || '';

      return this.token || decodeURIComponent(safeMatches);
    }

    /** Метод для сохранения токена юзера в куку 'token'
     * @param {String} token - токен юзера для сохранения
     * @param {Number} maxAge - срок жизни токена в мс, по-умолчанию 86400
     * */
    public static setToken(token: string, maxAge = 86400): void {
      this.token = token;
      document.cookie = `${encodeURIComponent('token')}=${encodeURIComponent(token)}; path=/; max-age=${maxAge}; samesite=lax`;
    }

    /** Метод для получения имени пользователя
     * @returns {String} имя пользователя
     * */
    public static getUserName(): string {
      return UserService.userName || localStorage.getItem('name') || '';
    }

    /** Метод для сохранения токена юзера в куку 'token'
     * @param {object} data - данные для авторизации, реализующие интерфейс IUserLoginData
     * @returns {string} jwt токен
     * */
    public async login(data: IUserLoginData): Promise<IUserLoginResponse> {
      const url = 'login';
      return this.http(url, EHttpMethods.Post, {}, JSON.stringify(data));
    }

    /** Метод для логаута */
    public static logout() {
      localStorage.removeItem('name');
      UserService.setToken(UserService.getToken(), -1);
      this.token = null;
      this.userName = null;
      window.location.reload();
    }

    /** Метод для получения информации о текущем пользователе */
    public async getSelf(): Promise<IUserInfo> {
      const url = 'self';
      return this.http<IUserInfo>(url, EHttpMethods.Get, this.getHeaders());
    }

    /** Метод для создания нового пользователя
     * @param {Object} data информация о пользователе IUserDraft
     * */
    public async create(data: IUserDraft): Promise<void> {
      const url = 'user';
      return this.http(url, EHttpMethods.Put, this.getHeaders(), JSON.stringify(data));
    }

    /** Метод для удаления пользователя
     * @param {string} guid guid пользователя
     * */
    public async delete(guid: string): Promise<void> {
      const url = `user/${guid}`;
      return this.http(url, EHttpMethods.Delete, this.getHeaders());
    }

    /** Метод для получения списка пользователей
     * @param {Object} pagination информация для пагинации
     * */
    public async getList(pagination = DEFAULT_PAGINATION): Promise<IBasePaginatedResponse<IUserInfo>> {
      const url = 'user';
      return this.http<IBasePaginatedResponse<IUserInfo>>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(pagination));
    }

    /** Метод для получения инфы о пользователе по guid
     * @param {string} guid guid пользователя
     * */
    public async getByGuid(guid: string): Promise<IUserInfo> {
      const url = `user/${guid}`;
      return this.http<IUserInfo>(url, EHttpMethods.Get, this.getHeaders());
    }

    /** Метод для изменения пароля пользователя
     * @param {string} guid guid пользователя
     * @param {string} password новый пароль пользователя
     * */
    public async setPassword(guid: string, password: string): Promise<void> {
      const url = `user/${guid}/password`;
      const data = { guid, password };

      return this.http<void>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(data));
    }
}
