import { ITableColumn } from '@/shared/components/layout/AppTable/models/table.model';

export const EDIT_ROLES_COLUMNS: Array<ITableColumn> = [
  {
    title: 'Title',
    name: 'title',
    width: '100px',
  },
  {
    title: 'Description',
    name: 'description',
    width: 'auto',
  },
  {
    title: 'Created',
    name: 'created',
    width: '170px',
  },
  {
    name: 'remove',
    width: '50px',
  },
];
