import { ConfigStorageService } from '@/shared/api/services/common/configStorage.service';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { UserService } from '@/shared/api/services/user/user.service';
import { EConfigStorageKeys } from '@/shared/api/services/common/enums/EConfigStorageKeys';
import { IAppConfig } from '@/shared/api/services/common/models/appConfig.model';

/**
 * Базовый сервис для работы с API
 * @module HttpService
 */
export class HttpService {
    protected readonly apiEndpoint: string;

    constructor() {
      this.apiEndpoint = ConfigStorageService.get('apiEndpoint') as string;
    }

    /**
     * Возвращает заголовки с авторизацией
     * @returns {object} Заголовки
     */
    protected getHeaders(): HeadersInit {
      return ({
        Authorization: `Bearer ${UserService.getToken()}`,
      });
    }

    /**
     * Возвращает урл для обращения к api
     * @returns {string} Method API url
     */
    // eslint-disable-next-line max-len
    protected getMethodUrl(url: string, { endpoint = EConfigStorageKeys.apiEndpointV1 }: Record<string, EConfigStorageKeys> = {}): string {
      return `${ConfigStorageService.get(endpoint as keyof IAppConfig)}${url}`;
    }

    /**
     * Возвращает урл для обращения к api
     * @param {string} url API url без эндпойнта
     * @param {EHttpMethods} method HTTP метод из энама EHttpMethods, по умолчанию GET
     * @param {HeadersInit} headers заголовки запроса
     * @param {BodyInit} body данные для отправки
     * @param endpoint
     */
    protected http<ResponseInterface>(
      url: string,
      method: EHttpMethods = EHttpMethods.Get,
      headers: HeadersInit,
      body?: BodyInit,
      {
        endpoint = EConfigStorageKeys.apiEndpointV1,
        noCors = false,
      } = {},
    ): ResponseInterface {
      const methodURL = this.getMethodUrl(url, { endpoint });
      const options: RequestInit = {
        mode: noCors ? 'no-cors' : undefined,
      } as RequestInit;

      if (method) {
        options.method = method;
      }

      if (headers) {
        options.headers = headers;
      }

      if (body) {
        options.body = body;
      }

      return new Promise((resolve, reject) => {
        fetch(methodURL, options)
          .then((res) => {
            if (!res.ok) {
              if (res.status === 401) {
                UserService.logout();
              }
              return res.json().then((err) => reject(err));
            }
            return resolve(res.json());
          })
          .then((data) => resolve(data))
          .catch((err) => {
            console.log(err);
            return reject(err);
          });
      }) as unknown as ResponseInterface;
    }
}
