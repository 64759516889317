import { defineStore } from 'pinia';
import { ref } from 'vue';
import { IRole } from '@/shared/models/role.model';
import { IUserInfo } from '@/shared/models/user.model';
import { RolesUtil } from '@/shared/utils/roles.util';
import router from '@/router';

/** Хранилище данных юзера
 * @property setAuth установка статуса авторизации boolean
 * @property onFetchUser установка данных о юзере
 * @property isAuth статус авторизации пользователя
 * @property isAdmin является ли юзер админом
 * @property username имя пользователя
 * @property roles массив ролей пользователя
 * */
export const useUserStore = defineStore('user', () => {
  const isAuth = ref(false);
  const isAdmin = ref(false);
  const username = ref('');
  const userdata = ref<IUserInfo>();
  const roles = ref<Array<IRole>>([]);

  async function onFetchUser(userInfo: IUserInfo): Promise<void> {
    RolesUtil.init(userInfo);
    userdata.value = userInfo;
    username.value = userInfo.login;
    roles.value = userInfo.roles;
    isAdmin.value = !!userInfo.roles.find((el) => el.name === 'admin');
  }

  function setAuth(value: boolean) {
    isAuth.value = value;
  }

  return {
    isAuth, isAdmin, username, roles, onFetchUser, setAuth, userdata,
  };
});
