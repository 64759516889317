import { HttpService } from '@/shared/api/services/common/http.service';
import { UserService } from '@/shared/api/services/user';
import { IPicturesListRequestParams, IPicturesListResponse } from '@/shared/api/services/pictures/models/pictures.model';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { EPicturesFilterKinds } from '@/shared/enums/filters/picturesFilterKinds';
import { omit } from 'lodash';

export class PicturesService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: PicturesService;

    constructor() {
      super();
      PicturesService.token = UserService.getToken();
      PicturesService.headers = {
        Authorization: `bearer ${PicturesService.token}`,
      };

      if (PicturesService._instance) {
        return PicturesService._instance;
      }

      PicturesService._instance = this;
    }

    /** Получить изображения по гуиду пиксета
     * @param {string} guid гуид пиксета
     * @param {params} params параметры
     */
    async getPicturesByPicset(guid: string, params: IPicturesListRequestParams = { limit: 50, order: 'picture.created desc', offset: 0 }): Promise<IPicturesListResponse> {
      const { limit, order, offset } = params;
      let url = `picture?_filter=picset_guid == "${guid}"&_order_by=${order}`;

      if (limit) {
        url += `&_limit=${limit}`;
      }

      if (offset) {
        url += `&_offset=${offset}`;
      }

      return this.http(url, EHttpMethods.Post, this.getHeaders());
    }

    async getPicturesByPicsetWithFilter(guid: string, {
      limit = 50,
      order = 'picture.created desc',
      offset = 0,
      filter = {},
    } = {}): Promise<IPicturesListResponse> {
      const internal = ['hideExcluded'];
      let url = `picture?_filter=picset_guid == "${guid}"`;

      if (Object.keys(filter).includes('hideExcluded') && Reflect.get(filter, 'hideExcluded')) {
        url += 'and excluded=="false"';
      }

      url += `&_order_by=${order}`;

      if (limit) {
        url += `&_limit=${limit}`;
      }

      if (offset) {
        url += `&_offset=${offset}`;
      }

      return this.http<IPicturesListResponse>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(omit(filter, internal)));
    }

    async getAllPictureWithFilter({
      limit = 50,
      order = 'picture.created desc',
      offset = 0,
      filter = {},
    } = {}): Promise<IPicturesListResponse> {
      let url = `picture?&_order_by=${order}`;

      if (limit) {
        url += `&_limit=${limit}`;
      }

      if (offset) {
        url += `&_offset=${offset}`;
      }

      return this.http<IPicturesListResponse>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(filter));
    }

    async getSpecialFilteredPictures({
      limit = 50,
      page = 1,
      filter = {} as specialFilter,
    }):Promise<IPicturesListResponse> {
      const url = 'meta/pictures';

      const payload = {
        filter,
        paging: {
          limit,
          page,
        },
      };

      return this.http<IPicturesListResponse>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(payload));
    }

    async setPictureTrained(guid, payload): Promise<void> {
      const url = `picture/${guid}/trained`;

      return this.http(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(payload));
    }
}

export type specialFilter = {
    kind: EPicturesFilterKinds;
    groups: Array<string>;
    categories: Array<string>;
    excluded: boolean,
}
