export class DateUtil {
  public static formatTimeFromFullDate(date: string): string {
    if (!date) return '';
    return new Date(date).toLocaleTimeString('ru');
  }

  public static formatDateFromFullDate(date: string): string {
    if (!date) return '';
    return new Date(date).toLocaleDateString('ru');
  }

  public static getTimeStringFromSeconds(seconds: number): string {
    if (!seconds) return '00:00';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedTime = `${(hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : '')
        + (minutes < 10 ? `0${minutes}` : minutes)}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;

    return formattedTime;
  }
}
