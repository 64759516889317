export class FunctionsUtil {
  public static useCallback(mainFunction: Function, callback: Function) {
    // eslint-disable-next-line func-names
    return function () {
      // eslint-disable-next-line prefer-rest-params,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      const result = mainFunction.apply(this, arguments);
      callback();
      return result;
    };
  }
}
