export enum EActions {
    God,
    ViewPreparing,
    ViewTrained,
    ViewImages,
    ViewCategories,
    ViewVideos,
    ViewErrors,
    ViewAll,
    ViewUsers,
    ViewAllPreparingPicsets,
    ViewSelfPreparingPicsets,
    CreatePicset,
    DeletePreparingPicset,
    SetPicsetReadiness,
    SetPicsetInitial,
    SetPicsetPictures,
    SetPicsetOwner,
    SavePicset,
    SetValidatedGroup,
    SetTrainedCategory,
}
