import {
  IPicture, ITrained,
} from '@/shared/models/picture.model';
import { IVideoThumb } from '@/shared/api/services/neuropass/models/videoThumb.model';
import { ISeriesItem } from '@/shared/models/series.model';
import { GroupsUtil } from '@/shared/utils/groups.util';
import { EGender } from '@/shared/enums/genders';
import { GenderUtil } from '@/shared/utils/gender.util';

export class PicsetUtil {
  public static getPrevailingGroupsList(
    picturesList: Array<IPicture | IVideoThumb>,
    seriesList: Array<ISeriesItem>,
  ): Array<string> | Set<string> {
    if (!picturesList?.length) return [];

    const list = picturesList?.map((pict: IPicture | IVideoThumb) => {
      if ('trained' in pict) {
        return pict.trained;
      }
      if ('thumb' in pict && 'detail' in (pict.thumb as unknown as IVideoThumb)) {
        return pict.thumb;
      }
      return undefined;
    }).filter((item) => item) as unknown as Array<ITrained>;

    return list.reduce((acc: Set<string>, trained) => {
      trained.forEach((trainedItem) => {
        const prevalings = trainedItem.groups
          ?.filter((group) => group.prevailing)
          .map((group) => group.prevailing as string) || [];
        if (prevalings.length) {
          prevalings.forEach((prevailing) => {
            const groupName = GroupsUtil.getGroupByCategoryName(prevailing, seriesList)?.title || prevailing;
            acc.add(groupName);
          });
        }
      });
      return acc;
    }, new Set<string>());
  }

  public static getPrevailingsGroupsListByCategoriesTitles(
    prevailingCategoriesList: Array<string> | Set<string>,
    seriesList: Array<ISeriesItem>,
  ): Array<string> | Set<string> {
    return new Set(
      // eslint-disable-next-line max-len
      [...prevailingCategoriesList].map((prevailingCategory) => GroupsUtil.getGroupByCategoryName(prevailingCategory, seriesList)?.title || prevailingCategory),
    );
  }

  public static getPrevailingCategoriesList(picturesList: Array<IPicture | IVideoThumb>): Array<string> | Set<string> {
    if (!picturesList?.length) return [];

    const list = picturesList?.map((pict: IPicture | IVideoThumb) => {
      if ('trained' in pict) {
        return pict.trained;
      }
      if ('detail' in pict) {
        return pict.detail;
      }
      return undefined;
    }).filter((item) => item) as unknown as Array<ITrained>;

    return list.reduce((acc: Set<string>, trained) => {
      trained.forEach((trainedItem) => {
        const prevalings = trainedItem.groups
          ?.filter((group) => group.prevailing)
          .map((group) => group.prevailing as string) || [];
        if (prevalings.length) {
          prevalings.forEach(acc.add.bind(acc));
        }
      });
      return acc;
    }, new Set<string>());
  }
}
