export class MultiselectUtil {
  public static transformToOptions<ItemsType>(
    target: Array<ItemsType>,
    titleProp = 'title' as keyof ItemsType,
    idProp = 'guid' as keyof ItemsType,
  ) {
    return target.map((item) => ({
      label: item[titleProp],
      value: item[idProp],
    }));
  }

  public static transformToSimpleOptions<ItemsType>(
    target: Array<ItemsType>,
    titleProp = 'title' as keyof ItemsType,
  ) {
    return target.map((item) => item[titleProp]);
  }

  public static transformEnumToOptions<Enum>(enumName: Enum): Array<string> {
    return Object.values(enumName);
  }
}
