import { EGender } from '@/shared/enums/genders';
import { ref, Ref } from 'vue';
import { IMultiselectOption } from '@/shared/models/multiselect.model';

type TUseGenderFilter = {
    currentGenderFilter: Ref<TGenderFilterType>,
    setGenderFilter: (value: TGenderFilterType) => void,
    genderFilterOptions: Array<IMultiselectOption<TGenderFilterType>>,
}

type TGenderFilterType = EGender | 'any';

export function useGenderFilter(): TUseGenderFilter {
  const currentGenderFilter = ref<TGenderFilterType>('any');
  const setGenderFilter = function (value: TGenderFilterType): void {
    currentGenderFilter.value = value;
  };
  const genderFilterOptions: Array<IMultiselectOption<TGenderFilterType>> = [
    {
      label: 'Any',
      value: 'any',
    },
    {
      label: 'Female',
      value: EGender.Female,
    },
    {
      label: 'Male',
      value: EGender.Male,
    },
    {
      label: 'Shemale',
      value: EGender.Shemale,
    },
  ];

  return {
    currentGenderFilter,
    setGenderFilter,
    genderFilterOptions,
  };
}
