import { ITableColumn } from '@/shared/components/layout/AppTable/models/table.model';
import { IMenuOption } from '@/shared/models/ui.model';
import { EIconPaths } from '@/shared/enums/icons.enum';

export const USERS_LIST_COLUMNS: Array<ITableColumn> = [
  {
    name: 'checkbox',
    width: '45px',
  },
  {
    name: 'username',
    title: 'User',
    width: '200px',
    slotted: true,
  },
  {
    name: 'roles',
    title: 'Roles',
    width: 'auto',
    slotted: true,
  },
  {
    name: 'created',
    title: 'Registered',
    width: '170px',
  },
  {
    name: 'menu',
    width: '45px',
  },
];

export const USERS_LIST_MENU_OPTIONS: Array<IMenuOption> = [
  {
    title: 'Change password',
    id: 'password',
    icon: EIconPaths.Lock,
  },
  {
    title: 'Remove',
    id: 'remove',
    danger: true,
    icon: EIconPaths.Trash,
  },
];
