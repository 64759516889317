import { ICategory } from '@/shared/models/category.model';

const trashKeys = ['trash'];

export class CategoriesUtil {
  public static filterBySeriesTitle(categories: Array<ICategory>, series: string): Array<ICategory> {
    return categories.filter((category) => category.series?.title === series);
  }

  public static filterBySeriesGuid(categories: Array<ICategory>, guid: string | Array<string> | undefined): Array<ICategory> {
    if (Array.isArray(guid)) {
      return categories.filter((category) => {
        const categorySeriesGuid = category.series?.guid || '';
        return guid.includes(categorySeriesGuid);
      });
    }
    return categories.filter((category) => category.series?.guid === guid);
  }

  public static isTrashCategory(category: string): boolean {
    return trashKeys.some((trash) => category.includes(trash));
  }

  public static sortCategoriesByTitle(categories: Array<string>, prevailings: Map<string, string>): Array<string> {
    return categories.reduce((acc, category) => {
      if (prevailings.has(category)) {
        acc[0].push(category);
      } else if (CategoriesUtil.isTrashCategory(category)) {
        acc[2].push(category);
      } else {
        acc[1].push(category);
      }
      return acc;
    }, [[], [], []] as [Array<string>, Array<string>, Array<string>]).flat();
  }
}
