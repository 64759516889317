import { UserService } from '@/shared/api/services/user';
import { HttpService } from '@/shared/api/services/common/http.service';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import {
  ISeriesCreatePayload,
  ISeriesResponse,
} from '@/shared/api/services/series/models/seriesService.model';
import {
  IBasePayload,
  IBaseSingleResponse,
  IBaseSuccessPostResponse,
} from '@/shared/api/services/common/models/http.model';
import { ISeriesItem } from '@/shared/models/series.model';

/** Сервис для работы с группами(сериями)
 * @module SeriesService
 */

export class SeriesService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: SeriesService;

    constructor() {
      super();
      SeriesService.token = UserService.getToken();
      SeriesService.headers = {
        Authorization: `bearer ${SeriesService.token}`,
      };

      if (SeriesService._instance) {
        return SeriesService._instance;
      }

      SeriesService._instance = this;
    }

    /** Получить список серий\групп
     * @param {number} limit ограничение количества получаемых групп, опционально
     */
    async getList(limit?: number): Promise<ISeriesResponse> {
      let url = 'series';

      if (limit) {
        url = `${url}?_limit=${limit}`;
      }

      return this.http<ISeriesResponse>(url, EHttpMethods.Get, SeriesService.headers);
    }

    /** Создать новую группу\серию
     * @param {string} title Название создаваемой группы\серии
     */
    async create(title: string): Promise<IBaseSuccessPostResponse> {
      const url = 'series';

      const data: IBasePayload<ISeriesCreatePayload> = {
        payload: {
          title,
        },
      };

      return this.http<IBaseSuccessPostResponse>(url, EHttpMethods.Put, SeriesService.headers, JSON.stringify(data));
    }

    /** Изменить новую группу\серию
     * @param {string} guid Guid изменяемой группы
     * @param {string} title Новое название группы\серии
     */
    async update(guid: string, title: string): Promise<void> {
      const url = `series/${guid}`;

      const data: IBasePayload<ISeriesCreatePayload> = {
        payload: {
          title,
        },
      };

      return this.http(url, EHttpMethods.Patch, SeriesService.headers, JSON.stringify(data));
    }

    /** Получить инфо о группе по guid
     * @param {string} guid Guid группы
     */
    async getSeriesInfo(guid: string): Promise<IBaseSingleResponse<ISeriesItem>> {
      const url = `series/${guid}`;

      return this.http<IBaseSingleResponse<ISeriesItem>>(url, EHttpMethods.Get, SeriesService.headers);
    }

    /** Удалить группу по guid
     * @param {string} guid Guid группы
     */
    async delete(guid: string): Promise<IBaseSuccessPostResponse> {
      const url = `series/${guid}`;

      return this.http<IBaseSuccessPostResponse>(url, EHttpMethods.Delete, SeriesService.headers);
    }
}
