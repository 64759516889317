import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IPicset, IPicsetUpdateDraft } from '@/shared/models/picset.model';
import { ApiServices } from '@/shared/api/api';
import { IPicture } from '@/shared/models/picture.model';
import { IPicturesListRequestParams } from '@/shared/api/services/pictures/models/pictures.model';
import { IBasePayload } from '@/shared/api/services/common/models/http.model';

export enum EPicsetStoreWorkersNames {
  SetPicsetReadiness,
  GetPicsetPictures,
}

export const usePicsetStore = defineStore('picsetStore', () => {
  const PicsetApiService = new ApiServices.PicsetService();
  const PictureApiService = new ApiServices.PicturesService();

  const _picset = ref<IPicset>();
  const _picsetPictures = ref<Array<IPicture>>([]);
  const _picsetPicturesTotal = ref<number>(0);
  const _picsetPicturesPerPage = ref<number>(50);
  const _picsetPicturesOrder = ref('picture.created desc');
  const _picsetPicturesPage = ref<number>(1);
  const _picsetAllPicturesIds = ref<Array<string>>([]);
  const _groupFilter = ref<Array<string>>([]);
  const _categoryFilter = ref<Array<string>>([]);
  const _picsetStoreWorkers = ref<Set<EPicsetStoreWorkersNames>>(new Set());

  const picset = computed(() => _picset);
  const picsetPictures = computed(() => _picsetPictures);
  const picsetPicturesPage = computed(() => _picsetPicturesPage);
  const picsetPicturesTotal = computed(() => _picsetPicturesTotal);
  const picsetPicturesPerPage = computed(() => _picsetPicturesPerPage);
  const picsetPicturesOrder = computed(() => _picsetPicturesOrder);
  const picsetAllPicturesIds = computed(() => _picsetAllPicturesIds);
  const groupFilter = computed(() => _groupFilter);
  const categoryFilter = computed(() => _categoryFilter);
  const picsetStoreWorkers = computed(() => _picsetStoreWorkers);

  function getDefaultPicsetRequestParams(): IPicturesListRequestParams {
    return ({
      limit: _picsetPicturesPerPage.value,
      order: _picsetPicturesOrder.value,
      offset: _picsetPicturesPerPage.value * (_picsetPicturesPage.value - 1),
    });
  }

  async function getPicsetInfo(guid: string): Promise<void> {
    const { result: picset } = await PicsetApiService.getPicsetByGuid(guid);
    _picset.value = picset;
  }

  async function getPicsetPictures(
    guid: string,
    filter = {},
    params = getDefaultPicsetRequestParams(),
  ): Promise<void> {
    if (_picsetStoreWorkers.value.has(EPicsetStoreWorkersNames.GetPicsetPictures)) return;
    _picsetStoreWorkers.value.add(EPicsetStoreWorkersNames.GetPicsetPictures);

    console.log(_picsetPicturesPage.value);

    const { pictures, info: { total } } = await PictureApiService
      .getPicturesByPicsetWithFilter(guid, { ...params, filter: { ...filter } });
    _picsetPictures.value = [];
    [_picsetPictures.value, _picsetPicturesTotal.value] = [pictures, total];

    _picsetStoreWorkers.value.delete(EPicsetStoreWorkersNames.GetPicsetPictures);
  }

  async function setPicsetReadiness(guid: string, value: boolean, picset = _picset.value): Promise<void> {
    if (_picsetStoreWorkers.value.has(EPicsetStoreWorkersNames.SetPicsetReadiness)) return;
    _picsetStoreWorkers.value.add(EPicsetStoreWorkersNames.SetPicsetReadiness);

    await PicsetApiService.setPicsetReadiness(guid, value);
    _picsetStoreWorkers.value.delete(EPicsetStoreWorkersNames.SetPicsetReadiness);
    if (picset) {
      Reflect.set(picset, 'isReady', value);
    }
  }

  async function getPicsetAllPicturesIds(guid: string): Promise<void> {
    const { pictures } = await PictureApiService.getPicturesByPicset(guid, { order: 'picture.created' });
    _picsetAllPicturesIds.value = pictures.map((picture) => picture.id);
  }

  function setPicturesPage(page: number): void {
    _picsetPicturesPage.value = page;
  }

  async function savePicsetPics(guid: string, ids: Record<string, boolean>): Promise<Object> {
    return PicsetApiService.savePicsetPics(guid, ids);
  }

  function toggleCategoryFilter(value: string): void {
    _categoryFilter.value.includes(value)
      ? _categoryFilter.value = _categoryFilter.value.filter((item) => item !== value)
      : _categoryFilter.value.push(value);
  }

  function toggleGroupFilter(value: string): void {
    if (_groupFilter.value.includes(value)) {
      _groupFilter.value = _groupFilter.value.filter((item) => item !== value);
    } else {
      _groupFilter.value.push(value);
    }
  }

  async function savePicsetData(guid: string, picsetData: IBasePayload<IPicsetUpdateDraft>): Promise<void> {
    await PicsetApiService.updatePicset(guid, picsetData);
  }

  async function validatePicsetGroups(guid: string, groups: Array<string>, mode: boolean, callback?: Function): Promise<void> {
    await PicsetApiService.validatePicsetGroups(guid, groups, mode);
    await getPicsetInfo(guid);

    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  async function deletePicset(guid: string, callback?: Function): Promise<void> {
    await PicsetApiService.deletePicset(guid);

    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  return ({
    picset,
    picsetPictures,
    picsetPicturesPage,
    picsetPicturesPerPage,
    picsetPicturesTotal,
    picsetAllPicturesIds,
    picsetStoreWorkers,
    groupFilter,
    categoryFilter,
    getPicsetInfo,
    getPicsetPictures,
    getPicsetAllPicturesIds,
    setPicturesPage,
    savePicsetPics,
    savePicsetData,
    deletePicset,
    toggleGroupFilter,
    toggleCategoryFilter,
    setPicsetReadiness,
    validatePicsetGroups,
  });
});
