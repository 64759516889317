import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ICategoriesFilter, ICategory, ICategoryDraft } from '@/shared/models/category.model';
import { ApiServices } from '@/shared/api/api';
import { IBaseSuccessPostResponse } from '@/shared/api/services/common/models/http.model';

/** Хранилище данных о категориях
 * */

export const useCategoriesStore = defineStore('useCategoriesStore', () => {
  const CategoriesApiService = new ApiServices.CategoriesService();

  const _categoriesList = ref<Array<ICategory>>([]);
  const _categoriesCount = ref<number>(0);
  const _categoriesTotal = ref<number>(0);
  const _categoryInfo = ref<ICategory>();

  const categoriesList = computed(() => _categoriesList);
  const categoriesCount = computed(() => _categoriesCount);
  const categoriesTotal = computed(() => _categoriesTotal);
  const categoryInfo = computed(() => _categoryInfo);

  async function getCategories(filter?: ICategoriesFilter): Promise<void> {
    const { categories, info: { count, total } } = await CategoriesApiService.getList(filter);
    [_categoriesList.value, _categoriesCount.value, _categoriesTotal.value] = [categories, count, total];
  }

  async function editCategory(guid: string, title: string, series: string) {
    try {
      const payload = {
        title,
        series: {
          title: series,
        },
      };

      await CategoriesApiService.update(guid, { payload });
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoryInfo(guid: string): Promise<void> {
    const { result: fetchedCategoryInfo } = await CategoriesApiService.getByGuid(guid);
    _categoryInfo.value = fetchedCategoryInfo;
  }

  async function createCategory(title: string, series?: string): Promise<IBaseSuccessPostResponse> {
    const payload: ICategoryDraft = {
      title,
      series: {
        title: series || '',
      },
    };

    return CategoriesApiService.create({ payload });
  }

  async function removeCategory(guid: string): Promise<IBaseSuccessPostResponse> {
    return CategoriesApiService.delete(guid);
  }

  return {
    categoriesList,
    categoriesCount,
    categoriesTotal,
    categoryInfo,
    getCategories,
    getCategoryInfo,
    editCategory,
    createCategory,
    removeCategory,
  };
});
