import { EGender } from '@/shared/enums/genders';
import { IPicture } from '@/shared/models/picture.model';
import { IVideoThumb } from '@/shared/api/services/neuropass/models/videoThumb.model';

const FEMALE_VARIATIONS = [
  'female',
  'girl',
  'girl_1',
  'girl_2',
  'girl_3',
  'girl_4',
  'girl_5',
  'woman',
];

const MALE_VARIATIONS = [
  'male',
  'male_1',
  'male_2',
  'male_3',
  'male_4',
  'male_5',
  'male_6',
  'man',
  'men',
  'boy',
  'guy',
];

const SHEMALE_VARIATIONS = [
  'shemale',
  'shemale_1',
  'shemale_2',
  'shemale_3',
  'shemale_4',
  'shemale_5',
  'shemale_6',
  'trans',
];

export class GenderUtil {
  public static detectGender(gender: string): EGender {
    if (GenderUtil.isShemale(gender)) return EGender.Shemale;
    if (GenderUtil.isFemale(gender)) return EGender.Female;
    return EGender.Male;
  }

  public static getGenderPrefix(gender: string): string {
    switch (GenderUtil.detectGender(gender)) {
    case EGender.Shemale:
      return 'S';
    case EGender.Female:
      return 'F';
    default:
      return 'M';
    }
  }

  public static isFemale(gender: string): boolean {
    return FEMALE_VARIATIONS.includes(gender.toLowerCase());
  }

  public static isMale(gender: string): boolean {
    return MALE_VARIATIONS.includes(gender.toLowerCase());
  }

  public static isShemale(gender: string): boolean {
    return SHEMALE_VARIATIONS.includes(gender.toLowerCase());
  }

  public static hasPictureTrainedWithGender(picture: IPicture | IVideoThumb, _gender: EGender | 'any'): boolean {
    const trained = 'trained' in picture ? picture.trained : (picture as IVideoThumb).detail;
    return !!trained?.filter(({ gender }) => GenderUtil.detectGender(gender) === _gender).length;
  }
}
