function applyGuards(guards, from, to, lastNext, i) {
  const guard = guards[i];

  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, (nextArg) => {
      // eslint-disable-next-line default-case
      switch (typeof (nextArg)) {
      case 'undefined':
        applyGuards(guards, from, to, lastNext, i + 1);
        break;
      case 'object':
        lastNext(nextArg);
        break;
      case 'boolean':
        lastNext(nextArg);
        break;
      case 'string':
        lastNext(nextArg);
        break;
      }
    });
  }
}

export function useGuards(guardsList: Array<Function>) {
  // eslint-disable-next-line func-names
  return function (from, to, next) {
    applyGuards(guardsList, from, to, next, 0);
  };
}
