import router from '@/router/index';
// import router from 'vue-router';
import { _router } from '@/main';

export class UrlParamsFilterUtil {
  /** Метод для парсинга параметров фильтрации из адресной строки */
  public static parseFilterParams<T extends string>(params: Array<T>): Record<T, string> {
    return params.reduce((result, param) => {
      const params = new URLSearchParams(window.location.search);
      if (params.has(param)) {
        Reflect.set(result, param, params.get(param as unknown as string));
      }
      return result;
    }, {} as Record<T, string>);
  }

  /** Метод для записи параметров поиска в адресную строку */
  // eslint-disable-next-line max-len
  public static setFilterParamsToUrl<useRoute, filterParams = Record<string, string>>(useRoute: useRoute, filterParams: filterParams): void {
    if (typeof useRoute === 'function') {
      const query = useRoute()?.query || {};

      const _filterParams = Object.entries(filterParams).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, string>);

      _router.replace({ query: { ...query, ..._filterParams } });
    }
  }
}
