import { HttpService } from '@/shared/api/services/common/http.service';
import { UserService } from '@/shared/api/services/user';
import { DEFAULT_PAGINATION } from '@/shared/api/services/common/constants/pagination.const';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { IBasePaginatedResponse } from '@/shared/api/services/common/models/http.model';
import { IRole, IRoleDraftData } from '@/shared/models/role.model';

/** Сервис для работы с ролями пользователя
 * @module RolesService
 */

export class RolesService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: RolesService;

    constructor() {
      super();
      RolesService.token = UserService.getToken();
      RolesService.headers = {
        Authorization: `bearer ${RolesService.token}`,
      };

      if (RolesService._instance) {
        return RolesService._instance;
      }

      RolesService._instance = this;
    }

    /** Метод для получения списка ролей
     * @param {Object} pagination информация для пагинации
     * */
    public async getList(pagination = DEFAULT_PAGINATION): Promise<IBasePaginatedResponse<IRole>> {
      const url = 'role';
      return this.http<IBasePaginatedResponse<IRole>>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(pagination));
    }

    /** Метод для создания роли
     * @param {Object} roleData данные создаваемой роли
     * */
    public async create(roleData: IRoleDraftData): Promise<void> {
      const url = 'role';

      return this.http<void>(url, EHttpMethods.Put, this.getHeaders(), JSON.stringify(roleData));
    }

    /** Метод для удаления роли
     * @param {string} guid guid роли
     * */
    public async delete(guid: string): Promise<void> {
      const url = `role/${guid}`;

      return this.http<void>(url, EHttpMethods.Delete, this.getHeaders());
    }
}
