import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ApiServices } from '@/shared/api/api';
import { IUserDraft, IUserInfo } from '@/shared/models/user.model';
import { IBasePaginationData } from '@/shared/api/services/common/models/http.model';

export const useUsersStore = defineStore('usersStore', () => {
  const UserApiService = new ApiServices.UserService();
  const perPage = 100;

  const _usersList = ref<Array<IUserInfo>>([]);
  const _usersPages = ref<number>(0);
  const _userPage = ref<number>(1);
  const _usersTotal = ref<number>(0);

  const usersList = computed(() => _usersList);
  const usersPages = computed(() => _usersPages);
  const usersTotal = computed(() => _usersTotal);
  const usersPage = computed(() => _userPage);

  async function getUsers(limit?: number): Promise<void> {
    const { items, pages, total } = await UserApiService.getList(getUserPagination(limit));
    [_usersList.value, _usersPages.value, _usersTotal.value] = [items, Number(pages), Number(total)];
  }

  async function createUser(userData: IUserDraft): Promise<void> {
    await UserApiService.create(userData);
  }

  function setUsersPage(page: number): void {
    _userPage.value = page;
  }

  function getUserPagination(_perPage?: number): IBasePaginationData {
    return ({
      pagination: {
        page: _userPage.value,
        limit: _perPage || perPage,
        order: null,
      },
    });
  }

  async function setUserPassword(guid: string, password: string): Promise<void> {
    return UserApiService.setPassword(guid, password);
  }

  return {
    usersList,
    usersPage,
    usersPages,
    usersTotal,
    getUsers,
    setUsersPage,
    createUser,
    setUserPassword,
  };
});
