import { Ref, ref } from 'vue';
import { ITrainedExtendedItem } from '@/shared/models/picture.model';
import { ApiServices } from '@/shared/api/api';

export class TrainedPictureStore {
   private currentActiveIndex: Ref<number>;

   private pictureSelectedCategories: Ref<Array<string>>;

   constructor() {
     this.currentActiveIndex = ref(-1);
     this.pictureSelectedCategories = ref([]);
   }

   public unselectAll(): void {
     this.pictureSelectedCategories.value = [];
   }

   public setTrained(index: number): void {
     this.currentActiveIndex.value = this.currentActiveIndex.value === index ? -1 : index;
   }

   public getCurrentIndex(): number {
     return this.currentActiveIndex.value;
   }

   public showStateByIndex(index: number): boolean {
     return this.getCurrentIndex() === -1 || this.getCurrentIndex() === index;
   }

   public getPictureSelectedCategories(): Ref<Array<string>> {
     return this.pictureSelectedCategories;
   }

   public togglePictureSelectedCategories(category: string) {
     this.currentActiveIndex.value = -1;
     if (this.pictureSelectedCategories.value.includes(category)) {
       this.pictureSelectedCategories.value = this.pictureSelectedCategories.value.filter((item) => item !== category);
     } else {
       this.pictureSelectedCategories.value.push(category);
     }
   }

   public isItemAllowByFilter(trainedItem: ITrainedExtendedItem): boolean {
     if (!this.getPictureSelectedCategories().value.length) {
       return true;
     }
     const itemCategories = trainedItem.groups.map(({ category }) => category.join(''));
     return this.getPictureSelectedCategories().value.every((item) => itemCategories.some((_item) => _item === item));
   }
}
