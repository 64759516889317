import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useSliderStore = defineStore('slider', ({ limit = Infinity } = {}) => {
  const _currentIndex = ref<number>(0);

  const currentIndex = computed(() => _currentIndex);

  function next(): void {
    if (_currentIndex.value + 1 >= limit) {
      return;
    }
    _currentIndex.value += 1;
  }

  function previous(): void {
    if (_currentIndex.value <= 0) {
      return;
    }
    _currentIndex.value -= 1;
  }

  return ({
    currentIndex,
    next,
    previous,
  });
});
