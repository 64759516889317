export class ConfirmationUtil {
  public static createConfirmationRequest(confirm: Function, reject: Function, text?: string): Record<string, Function | string> {
    const result: Record<string, Function | string> = {};
    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => {
      result.confirm = resolve;
      result.decline = reject;
      result.innerHtml = text || '';
    })
      .then(() => {
        confirm();
      })
      .catch(() => {
        reject();
      });

    return result;
  }
}
