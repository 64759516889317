import { defineStore } from 'pinia';
import { ref } from 'vue';

/** Хранилище данных страницы новых пиксетов
 * @property selectedPicsetsGuidsList сет выбранных пиксетов
 * */

export const usePreparingPicsetsListStore = defineStore('preparingPicsetsList', () => {
  const selectedPicsetsGuidsSet = ref<Set<string>>(new Set());

  function onTogglePicset(value: boolean, guid: string): void {
    value
      ? selectedPicsetsGuidsSet.value.add(guid)
      : selectedPicsetsGuidsSet.value.delete(guid);
  }

  return { selectedPicsetsGuidsSet, onTogglePicset };
});
