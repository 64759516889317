import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ApiServices } from '@/shared/api/api';
import { IVideo, IVideoThumb } from '@/shared/api/services/neuropass/models/videoThumb.model';

export const useVideoThumbsStore = defineStore('videoThumbs', () => {
  const NeuropassService = new ApiServices.NeuropassService();

  const limitDefault = 50;

  const _videoThumbsList = ref<Array<IVideoThumb>>([]);
  const _videoThumbsCount = ref<number>(0);
  const _videoThumbsTotal = ref<number>(0);
  const _videoThumbsPage = ref<number>(1);
  const _videoThumbsInfo = ref<IVideo>();

  const videoThumbsList = computed(() => _videoThumbsList);
  const videoThumbsCount = computed(() => _videoThumbsCount);
  const videoThumbsTotal = computed(() => _videoThumbsTotal);
  const videoThumbsPage = computed(() => _videoThumbsPage);
  const videoThumbsInfo = computed(() => _videoThumbsInfo);

  async function getVideoThumbsList(limit = limitDefault, filters = {}): Promise<void> {
    const { payload, info: { count, total } } = await NeuropassService.getList(limit, _videoThumbsPage.value, { filters });
    [_videoThumbsList.value, _videoThumbsCount.value, _videoThumbsTotal.value] = [payload, count, total];
  }

  async function getVideoThumbsByGuid(guid: string): Promise<void> {
    const { video } = await NeuropassService.getSingleByGuid(guid);
    _videoThumbsInfo.value = video;
  }

  async function getVideoThumbsByMd5(md5: string): Promise<void> {
    const { video } = await NeuropassService.getSingleByMd5(md5);
    _videoThumbsInfo.value = video;
  }

  async function setPage(pageNum: number) {
    _videoThumbsPage.value = pageNum;
    _videoThumbsList.value = [];
    // await getVideoThumbsList();
  }

  async function videosThumbsResetState() {
    _videoThumbsTotal.value = 0;
    _videoThumbsCount.value = 0;
    _videoThumbsPage.value = 1;
    _videoThumbsList.value = [];
    _videoThumbsInfo.value = undefined;
  }

  return {
    videoThumbsList,
    videoThumbsCount,
    videoThumbsTotal,
    videoThumbsInfo,
    videoThumbsPage,
    getVideoThumbsList,
    setPage,
    videosThumbsResetState,
    getVideoThumbsByGuid,
    getVideoThumbsByMd5,
  };
});
