export enum EIconPaths {
    ArrowLong = 'icon-arrow-long.svg#arrow-long',
    Blocked = 'icon-blocked.svg#blocked',
    Checkmark = 'icon-checkmark.svg#checkmark',
    Close = 'icon-close.svg#close',
    Copy = 'icon-copy.svg#copy',
    Cross = 'icon-cross.svg#cross',
    Dots = 'icon-dots.svg#dots',
    Download = 'icon-download.svg#download',
    Edit = 'icon-edit.svg#edit',
    Eye = 'icon-eye.svg#eye',
    EyeCrossed = 'icon-eye-crossed.svg#eye-crossed',
    Filter = 'icon-filter.svg#filter',
    GenderFemale = 'icon-gender-female.svg#gender-female',
    GenderMale = 'icon-gender-male.svg#gender-male',
    Lock = 'icon-lock.svg#lock',
    NicheGay = 'icon-niche-gay.svg#niche-gay',
    NicheShemale = 'icon-niche-shemale.svg#niche-shemale',
    NicheStraight = 'icon-niche-straight.svg#niche-straight',
    ResetFilters = 'icon-reset-filter.svg#reset-filter',
    Search = 'icon-search.svg#search',
    Slash = 'icon-slash.svg#slash',
    SortAsc = 'icon-sort-asc.svg#sort-asc',
    SortDesc = 'icon-sort-desc.svg#sort-desc',
    Trash = 'icon-trash.svg#trash',
    Triangle = 'icon-triangle.svg#triangle',
    Zip = 'icon-zip.svg#zip',
}
