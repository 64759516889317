import { AppCreateUserModal, AppEditRolesModal, AppChangePasswordModal } from '@/pages/users-list/';
import { Component, computed, ref } from 'vue';
import { EModalsList } from '@/shared/enums/models.enum';
import { AppCreateGroupModal, AppCreateCategoryModal } from '@/pages/categories';
import AppConfirmModal from '@/shared/components/modals/AppConfirmModal.vue';
import { AppCreatePicsetModal } from '@/pages/picset-prepare';
import { AppCreatePicsetByVideoModal } from '@/pages/videos-thumbs/';

export class ModalsManager {
    private static modalsList = new Map<EModalsList, Component>([
      [EModalsList.CreateUser, AppCreateUserModal],
      [EModalsList.SetUserPassword, AppChangePasswordModal],
      [EModalsList.EditRoles, AppEditRolesModal],
      [EModalsList.CreateGroup, AppCreateGroupModal],
      [EModalsList.CreateCategory, AppCreateCategoryModal],
      [EModalsList.Confirm, AppConfirmModal],
      [EModalsList.CreatePicset, AppCreatePicsetModal],
      [EModalsList.CreatePicsetByThumbset, AppCreatePicsetByVideoModal],
    ]);

    private static activeModal = ref<Component | undefined>();

    private static activeModalBindings = ref<Object>({});

    public static getActiveModal = computed(() => ModalsManager.activeModal.value);

    public static getActiveModalBindings = computed(() => ModalsManager.activeModalBindings.value);

    public static setActiveModal(modal: EModalsList, bindings: Object = {}): void {
      ModalsManager.activeModal.value = ModalsManager.modalsList.get(modal) || undefined;
      ModalsManager.activeModalBindings.value = bindings;
    }

    public static closeModal(): void {
      ModalsManager.activeModal.value = undefined;
      ModalsManager.activeModalBindings.value = {};
    }
}
