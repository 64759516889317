import { IAppConfig } from '@/shared/api/services/common/models/appConfig.model';

export abstract class ConfigStorageService {
    private static _config: IAppConfig;

    static store(obj: IAppConfig) {
      this._config = obj;
    }

    static get(key: keyof IAppConfig) {
      return this._config && this._config[key];
    }
}
