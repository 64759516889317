import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ApiServices } from '@/shared/api/api';
import { IPicset } from '@/shared/models/picset.model';
import { isEqual } from 'lodash';
import { EPicsetMode } from '@/shared/enums/picsets/picsetModeTypes.enum';
import Picset from '@/_legacy/modules/picset/Picset.vue';

export const usePicsetsStore = defineStore('picsetsStore', () => {
  const PicsetApiService = new ApiServices.PicsetService();

  const _picsetsList = ref<Array<IPicset>>([]);
  const _picsetsTotal = ref<number>(0);
  const _picsetsPage = ref<number>(1);
  const _ownerFilter = ref<string>('');
  const _statusFilter = ref<string>('');
  const _groupFilter = ref<string>('');
  const _categoryFilter = ref<string>('');
  const _readinessFilter = ref<string>('');
  const _stateFilter = ref<string>('');

  const picsetsList = computed(() => _picsetsList);
  const picsetsTotal = computed(() => _picsetsTotal);
  const picsetsPage = computed(() => _picsetsPage);
  const ownerFilter = computed(() => _ownerFilter);
  const statusFilter = computed(() => _statusFilter);
  const groupFilter = computed(() => _groupFilter);
  const categoryFilter = computed(() => _categoryFilter);
  const readinessFilter = computed(() => _readinessFilter);
  const stateFilter = computed(() => _stateFilter);

  function setPicsetsPage(page: number): void {
    _picsetsPage.value = page;
  }

  async function setPicsetReadiness(guid: string, status) {
    await PicsetApiService.setPicsetReadiness(guid, status);
  }

  async function getPicsetsList(perPage: number, sort?: string, mode = EPicsetMode.PREPARING): Promise<void> {
    const { info: { total }, picsets } = await PicsetApiService.getList(
      _picsetsPage.value,
      perPage,
      sort,
      {
        statusFilter: _readinessFilter.value,
        series: _groupFilter.value,
        category: _categoryFilter.value,
        owner: _ownerFilter.value,
        state: _stateFilter.value,
        mode,
      },
    );

    if (!isEqual(picsetsList.value, picsets)) {
      [_picsetsList.value, _picsetsTotal.value] = [picsets, total];
    }
  }

  async function setOwnerFilter(value: string): Promise<void> {
    _ownerFilter.value = _ownerFilter.value === value ? '' : value;
  }

  async function setStatusFilter(value: string): Promise<void> {
    _statusFilter.value = _statusFilter.value === value ? '' : value;
  }

  async function setGroupFilter(value: string): Promise<void> {
    _groupFilter.value = _groupFilter.value === value ? '' : value;
  }

  async function setCategoryFilter(value: string): Promise<void> {
    _categoryFilter.value = _categoryFilter.value === value ? '' : value;
  }

  async function setReadinessFilter(value: string): Promise<void> {
    _readinessFilter.value = _readinessFilter.value === value ? '' : value;
  }

  async function setStateFilter(value: string): Promise<void> {
    _stateFilter.value = _stateFilter.value === value ? '' : value;
  }

  async function archivePicset(guid: string): Promise<unknown> {
    return PicsetApiService.archivePicset(guid);
  }

  async function disablePicset(guid: string, disabled: boolean, { updateItem = false } = {}): Promise<void> {
    await PicsetApiService.disablePicset(guid, disabled);
    if (updateItem) {
      const found = _picsetsList.value.find((picset) => picset.guid === guid);

      if (found) {
        found.disabled = disabled;
      }
    }
  }

  return ({
    picsetsList,
    picsetsTotal,
    picsetsPage,
    ownerFilter,
    statusFilter,
    groupFilter,
    categoryFilter,
    readinessFilter,
    stateFilter,
    archivePicset,
    setPicsetsPage,
    getPicsetsList,
    setPicsetReadiness,
    setOwnerFilter,
    setStatusFilter,
    setGroupFilter,
    setCategoryFilter,
    setReadinessFilter,
    setStateFilter,
    disablePicset,
  });
});
