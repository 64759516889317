export enum EPaths {
    Preparing = '/preparing',
    Trained = '/trained',
    Images = '/images',
    Categories = '/categories',
    Users = '/users',
    Videos = '/videos',
    Erroneous = '/erroneous',
    Login = '/login',
    NotFound = '/404',
    List = '/list',
}
