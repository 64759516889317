import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IRole, IRoleDraft } from '@/shared/models/role.model';
import { ApiServices } from '@/shared/api/api';
import { IBasePaginationData } from '@/shared/api/services/common/models/http.model';

export const useRolesStore = defineStore('rolesStore', () => {
  const RolesApiService = new ApiServices.RolesService();
  const perPage = 1;

  const _rolesList = ref<Array<IRole>>([]);
  const _rolesPages = ref<number>(1);
  const _rolesPage = ref<number>(1);
  const _rolesCount = ref<number>(0);
  const _rolesTotal = ref<number>(0);

  const rolesList = computed(() => _rolesList);
  const rolesPages = computed(() => _rolesPages);
  const rolesPage = computed(() => _rolesPage);
  const rolesCount = computed(() => _rolesCount);
  const rolesTotal = computed(() => _rolesTotal);

  async function getRoles(limit?: number): Promise<void> {
    const { items, pages, total } = await RolesApiService.getList(getRolesPagination(limit));
    [_rolesList.value, _rolesPages.value, _rolesTotal.value] = [items, Number(pages), Number(total)];
  }

  async function createRole(role: IRoleDraft): Promise<void> {
    await RolesApiService.create({ role });
  }

  async function deleteRole(guid: string): Promise<void> {
    await RolesApiService.delete(guid);
  }

  function setRolesPage(page: number): void {
    _rolesPage.value = page;
  }

  function getRolesPagination(_perPage?: number): IBasePaginationData {
    return ({
      pagination: {
        page: _rolesPage.value,
        limit: _perPage || perPage,
        order: null,
      },
    });
  }

  return {
    rolesList,
    rolesPages,
    rolesPage,
    rolesCount,
    rolesTotal,
    getRoles,
    setRolesPage,
    createRole,
    deleteRole,
  };
});
