export enum EPicsetState {
    Error = 'ERROR',
    Parse = 'PARSE',
    Parsing = 'PARSING',
    Parsed = 'PARSED',
    Archived = 'ARCHIVED',
    Archive = 'ARCHIVE',
    Archiving = 'ARCHIVING',
}

export enum EPicsetMode {
    CHECKING = 'CHECKING',
    VALIDATED = 'VALIDATED',
}
