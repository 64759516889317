<template>
<div class="denied-page">
  Page not found
</div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
  .denied-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    font-size: 25px;
  }
</style>
