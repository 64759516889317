import { IValidationError } from '@/shared/models/validation.model';

export class ValidationUtil {
  public static createValidationError(field: string, text: string): IValidationError {
    return ({ field, text });
  }

  public static requiredValidator(value: unknown, field: string, enabled = true): IValidationError | undefined {
    if (Array.isArray(value)) {
      return value.length || !enabled
        ? undefined
        : ValidationUtil.createValidationError(field, 'Value is required');
    }
    return value && `${value}`.length || !enabled
      ? undefined
      : ValidationUtil.createValidationError(field, 'Value is required');
  }

  public static fileSizeValidator(value: Array<File> | undefined, field: string, enabled = true): IValidationError | undefined {
    if (value) {
      return Array.from(value).some((file) => file.size > 157286400) || !enabled
        ? undefined
        : ValidationUtil.createValidationError(field, 'File is too big');
    }
    return undefined;
  }

  public static urlValidator(value: string, field: string, enabled = true) {
    // eslint-disable-next-line
    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    return urlRegex.test(value) || !enabled
      ? undefined
      : ValidationUtil.createValidationError(field, 'Value does not look like url');
  }

  public static equalValuesValidator(values: Array<string>, field: string, enabled = true) {
    const [value1, value2] = values;
    console.log(value1, value2);
    return (value1 === value2) || !enabled
      ? undefined
      : ValidationUtil.createValidationError(field, 'Values are not equal');
  }
}
