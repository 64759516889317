import { IUserInfo } from '@/shared/models/user.model';
import { IRole } from '@/shared/models/role.model';
import { RoleActions } from '@/shared/constants/roleActions.const';
import { EActions } from '@/shared/enums/actions';
import {
  computed, ref, Ref, ComputedRef,
} from 'vue';

export class RolesUtil {
    private static userRoles: Ref<Array<IRole>> = ref([]);

    private static rolesDictionary: typeof RoleActions;

    public static init(user: IUserInfo) {
      RolesUtil.userRoles.value = user.roles;
      RolesUtil.rolesDictionary = RoleActions;
    }

    public static isAllow(action: EActions): ComputedRef {
      return computed(() => {
        if (!RolesUtil.userRoles?.value) return false;
        return RolesUtil.userRoles.value.some(({ name }) => {
          const actions = RolesUtil.rolesDictionary.get(name.toLowerCase());
          if (!actions) return;
          return actions.includes(EActions.God) || actions.includes(action);
        });
      });
    }
}
