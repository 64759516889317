import { ITableColumn } from '@/shared/components/layout/AppTable/models/table.model';
import { EPicsetCreatingWays } from '@/pages/preparing-list/enums/picsetCreatingWays.enum';
import { IDropdownFilterOption, IMenuOption } from '@/shared/models/ui.model';
import { EIconPaths } from '@/shared/enums/icons.enum';
import { EPicsetsListDropdownFilters } from '@/pages/preparing-list/enums/picsetsListFilters.enum';
import { EPicsetStatus } from '@/shared/enums/picsets/picsetStatus.enum';
import { NamingUtil } from '@/shared/utils/naming.util';
import { EActions } from '@/shared/enums/actions';

export const PREPARING_PICSETS_TABLE_COLUMNS: Array<ITableColumn> = [
  // {
  //   name: 'checkbox',
  //   width: '45px',
  //   alignCenter: true,
  //   slotted: true,
  // },
  {
    name: 'photo',
    width: '55px',
    alignCenter: true,
  },
  {
    name: 'query',
    title: 'Query',
    width: 'auto',
    sortable: 'query',
    slotted: true,
  },
  // {
  //   name: 'id',
  //   title: 'ID',
  //   width: '250px',
  // },
  {
    name: 'count',
    title: 'Quantity',
    sortable: 'parsed',
    width: '100px',
  },
  {
    name: 'status',
    title: 'Status',
    width: '100px',
    alignCenter: true,
  },
  {
    name: 'created',
    title: 'Created at',
    sortable: 'created',
    width: '145px',
  },
  {
    name: 'parsed',
    title: 'Parsed at',
    sortable: 'parsed_at',
    width: '145px',
  },
  {
    name: 'modified',
    title: 'Last modified',
    sortable: 'saved_at',
    width: '145px',
  },
  // {
  //   name: 'filetype',
  //   title: 'Format',
  //   width: '70px',
  //   alignCenter: true,
  // },
  {
    name: 'owner',
    title: 'Owner',
    filter: EPicsetsListDropdownFilters.Owner,
    width: '100px',
  },
  {
    name: 'ready',
    width: '50px',
    title: 'Ready',
    alignCenter: true,
    filter: EPicsetsListDropdownFilters.Readiness,
  },
  {
    name: 'visibility',
    title: 'Disabled',
    width: '75px',
    alignCenter: true,
  },
  {
    name: 'download',
    width: '50px',
    alignCenter: true,
  },
  {
    name: 'menu',
    width: '45px',
    alignCenter: true,
  },
];

export const PICSET_CREATE_WAYS_OPTIONS = [
  {
    value: EPicsetCreatingWays.Query,
    label: 'By Query',
  },
  {
    value: EPicsetCreatingWays.Url,
    label: 'By URL',
  },
  {
    value: EPicsetCreatingWays.Archive,
    label: 'By Archive',
  },
];

export const PICSET_MENU_OPTIONS: Array<IMenuOption> = [
  {
    icon: EIconPaths.Slash,
    title: 'Copy JSON',
    id: 'json',
    danger: false,
  },
  {
    icon: EIconPaths.Zip,
    title: 'Make/remake archive',
    id: 'archive',
    danger: false,
  },
  {
    icon: EIconPaths.Trash,
    title: 'Remove',
    id: 'remove',
    danger: true,
    role: EActions.DeletePreparingPicset,
  },
];

export const READINESS_FILTER_OPTIONS: Array<IDropdownFilterOption> = [
  {
    title: NamingUtil.capitalize(EPicsetStatus.READY),
    value: EPicsetStatus.READY,
  },
  {
    title: NamingUtil.capitalize(EPicsetStatus.UNDONE),
    value: EPicsetStatus.UNDONE,
  },
];
