import { IDropdownFilterOption } from '@/shared/models/ui.model';

export class DropdownFilterUtil {
  public static transformToDropdownFilterOptions<ItemsType>(
    source: Array<ItemsType>,
    prop: string,
    {
      sort = false,
      removeChars = '',
    } = {},
  ): Array<IDropdownFilterOption> {
    const mapped = source.map((item: Object): IDropdownFilterOption => ({ value: item[prop] })).filter((item) => item);

    if (sort) {
      mapped.sort(({ value: a }, { value: b }) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    }

    if (removeChars) {
      const regex = RegExp(`[${removeChars}]`);
      mapped.map(({ value }) => ({ value: value.replace(regex, '') }));
    }

    return mapped;
  }

  public static createEmittingValueFormatter(comparingTarget: string) {
    return (function emittingValueFormatter(value: string): string {
      return value === comparingTarget ? '' : value;
    });
  }
}
