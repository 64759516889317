import { createApp } from 'vue';
import App from '@/App.vue';
import { createPinia } from 'pinia';

// import store from './store'
// import router from './router'

import { ConfigStorageService } from '@/shared/api/services/common/configStorage.service';

import VueAwesomePaginate from 'vue-awesome-paginate';
import VueClickAway from 'vue3-click-away';
import 'vue-awesome-paginate/dist/style.css';
import router from '@/router';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
};

// https://www.npmjs.com/package/tiny-emitter
// https://www.npmjs.com/package/mitt

fetch(`${process.env.BASE_URL}config.json`)
  .then((res) => res.json())
  .then((config) => {
    if (config) {
      ConfigStorageService.store(config);

      createApp(App)
        .use(router)
        .use(VueClickAway)
        .use(VueAwesomePaginate)
        .use(Toast, toastOptions)
        .use(createPinia())
        .mount('#app');

      // const app = createApp(App).use(router).use(VueAwesomePaginate)
      // router.isReady().then(() => app.use(store).mount('#app'))
    }
  });

export const _router = { ...router };
