import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ISeriesItem } from '@/shared/models/series.model';
import { ApiServices } from '@/shared/api/api';
import { IBaseSuccessPostResponse } from '@/shared/api/services/common/models/http.model';

export const useSeriesStore = defineStore('series', () => {
  const SeriesApiService = new ApiServices.SeriesService();

  const limitDefault = 500;

  const _seriesList = ref<Array<ISeriesItem>>([]);
  const _seriesCount = ref<number>(0);
  const _seriesTotal = ref<number>(0);
  const _seriesInfo = ref<ISeriesItem>();

  const seriesList = computed(() => _seriesList);
  const seriesCount = computed(() => _seriesCount);
  const seriesTotal = computed(() => _seriesTotal);
  const seriesInfo = computed(() => _seriesInfo);

  async function getSeries({ limit = limitDefault } = {}): Promise<void> {
    const { series: fetchedSeriesList, info: { count, total } } = await SeriesApiService.getList(limit);
    [_seriesList.value, _seriesCount.value, _seriesTotal.value] = [fetchedSeriesList, count, total];
  }

  async function getSeriesInfo(guid: string): Promise<void> {
    const { result: fetchedData } = await SeriesApiService.getSeriesInfo(guid);
    _seriesInfo.value = fetchedData;
  }

  async function createSeries(title: string): Promise<IBaseSuccessPostResponse> {
    return SeriesApiService.create(title);
  }

  async function removeSeries(guid: string): Promise<IBaseSuccessPostResponse> {
    return SeriesApiService.delete(guid);
  }

  return {
    seriesList,
    seriesCount,
    seriesTotal,
    seriesInfo,
    getSeries,
    getSeriesInfo,
    createSeries,
    removeSeries,
  };
});
