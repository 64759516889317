import { ITableColumn } from '@/shared/components/layout/AppTable/models/table.model';

export const GROUPS_TABLE_COLUMNS: Array<ITableColumn> = [
  {
    name: 'group',
    slotted: true,
    needPadding: true,
    width: 'auto',
  },
  {
    name: 'remove',
    slotted: true,
    width: '45px',
  },
];
