import { ITableColumn } from '@/shared/components/layout/AppTable/models/table.model';
import { IMenuOption } from '@/shared/models/ui.model';
import { EIconPaths } from '@/shared/enums/icons.enum';
import { EActions } from '@/shared/enums/actions';

export const TRAINED_LIST_TABLE_DATA: Array<ITableColumn> = [
  {
    name: 'checkbox',
    width: '45px',
    alignCenter: true,
    slotted: true,
  },
  {
    name: 'query',
    title: 'Query',
    width: 'auto',
    sortable: 'query',
    slotted: true,
  },
  {
    name: 'validation',
    title: 'Validation',
    width: '300px',
  },
  {
    name: 'count',
    title: 'Quantity',
    sortable: 'quantity',
    width: '100px',
  },
  // {
  //   name: 'status',
  //   title: 'Status',
  //   width: '100px',
  // },
  {
    name: 'neural',
    title: 'Version',
    width: '200px',
  },
  {
    name: 'created',
    title: 'Created at',
    sortable: 'created',
    width: '150px',
  },
  {
    name: 'parsed',
    title: 'Parsed at',
    sortable: 'parsed_at',
    width: '150px',
  },
  {
    name: 'modified',
    title: 'Last modified',
    sortable: 'saved_at',
    width: '145px',
  },
  {
    name: 'menu',
    width: '45px',
    alignCenter: true,
  },
];

export const PICSET_MENU_OPTIONS: Array<IMenuOption> = [
  {
    icon: EIconPaths.Slash,
    title: 'Copy JSON',
    id: 'json',
    danger: false,
  },
  {
    icon: EIconPaths.Trash,
    title: 'Remove',
    id: 'remove',
    danger: true,
    role: EActions.DeletePreparingPicset,
  },
];
