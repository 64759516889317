import { RolesUtil } from '@/shared/utils/roles.util';
import { EActions } from '@/shared/enums/actions';

export const PermissionsMap = new Map([
  ['/users', EActions.ViewUsers],
  ['/categories', EActions.ViewCategories],
  // ['/videos', EActions.ViewVideos],
  // ['/preparing', EActions.ViewPreparing],
  // ['/trained', EActions.ViewTrained],
  // ['/images', EActions.ViewImages],
  // ['/', EActions.ViewPreparing],
]);

export async function permissionsHook(to, from, next) {
  if (to.path === '/404' || to.path === '/' || to.path === '/login') {
    next();
    return;
  }

  if ([...PermissionsMap.keys()].includes(to.path)) {
    const permission = PermissionsMap.get(to.path);

    if (permission === undefined) {
      next('/404');
    } else {
      RolesUtil.isAllow(permission).value ? next() : next('/404');
    }
  } else {
    next();
  }
}
