export class FileHelperUtil {
  public static async downloadFile(url: string, name: string, ext = 'zip'): Promise<void> {
    await fetch(url)
    // eslint-disable-next-line prefer-promise-reject-errors
      .then((resp) => (resp.status === 200 ? resp.blob() : Promise.reject('something went wrong')))
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.target = '_blank';
        a.href = url;
        a.download = `${name}.${ext}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Failed download archive'));
    await Promise.resolve();
  }
}
