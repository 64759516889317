export class ClipboardUtil {
  public static copy(value: unknown, { raw = false } = {}): void {
    const idsList = raw ? value as string : JSON.stringify(value, null, 2);
    if (!idsList) return;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(idsList);
    } else {
      const tempInput = document.createElement('textarea');
      tempInput.value = idsList;
      document.body.append(tempInput);
      tempInput.select();
      document.execCommand('copy');
      tempInput.remove();
    }
  }
}
