import { HttpService } from '@/shared/api/services/common/http.service';
import { UserService } from '@/shared/api/services/user/user.service';
import { IPicsetDraft, IPicsetThumbsetDraft, IPicsetUpdateDraft } from '@/shared/models/picset.model';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { EPicsetMode } from '@/shared/enums/picsets/picsetModeTypes.enum';
import { EPicsetStatus } from '@/shared/enums/picsets/picsetStatus.enum';
import {
  IPicsetServiceListResponse,
  IPicsetServicePicsetItemResponse,
} from '@/shared/api/services/picset/models/picsetService.model';
import { IBasePayload } from '@/shared/api/services/common/models/http.model';
import { EConfigStorageKeys } from '@/shared/api/services/common/enums/EConfigStorageKeys';

/** Сервис для работы с пиксетами
 * @module PicsetService
 */

export class PicsetService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: PicsetService;

    constructor() {
      super();
      PicsetService.token = UserService.getToken();
      PicsetService.headers = {
        Authorization: `bearer ${PicsetService.token}`,
      };

      if (PicsetService._instance) {
        return PicsetService._instance;
      }

      PicsetService._instance = this;
    }

    /** Получить пиксет по гуиду
     * @param {string} guid гуид пиксета
     */
    async getPicsetByGuid(guid: string): Promise<IPicsetServicePicsetItemResponse> {
      const url = `picset/${guid}`;
      return this.http<IPicsetServicePicsetItemResponse>(url, EHttpMethods.Get, PicsetService.headers);
    }

    /** Получить список пиксетов
     * @param {number} page номер страницы
     * @param {number} limit количество элементов на странице
     * @param {string} sort сортировка в формате "поле asc/desc"
     * @param {string} mode режим пиксета из словаря EPicsetMode
     * @param {object} filter настройки фильтра
     * @param {string} owner имя пользователя, создавшего пиксет
     * @param {string} query запрос, по которому изначально создан пиксет
     * @param {boolean} isTrained обработан ли пиксет нейронкой (по-умолчанию false)
     * @param {string} statusFilter статус пиксета
     * @async
     */
    async getList(
      page: number,
      limit: number,
      sort = 'picset.created desc',
      {
        statusFilter = '',
        mode = EPicsetMode.PREPARING,
        series = '',
        category = '',
        owner = '',
        isTrained = false,
        state = '',
      } = {},
    ) {
      let url = `picsets?_filter=mode == '${mode}'`;

      const payload: Record<string, string> = {};

      if (series) {
        payload.series = series;
      }

      if (category) {
        payload.category = category;
      }

      if (state) {
        payload.state = state;
      }

      if (statusFilter) {
        if (statusFilter === EPicsetStatus.READY) {
          url = `${url}and is_ready == 'true'`;
        }

        if (statusFilter === EPicsetStatus.UNDONE) {
          url = `${url}and is_ready == 'false'`;
        }
      }

      if (owner) {
        url = `${url}and owner == '${owner}'`;
      }

      if (sort) {
        url = `${url}&_order_by=${sort}`;
      }

      if (page) {
        url = `${url}&_offset=${(page - 1) * limit}`;
      }

      if (limit) {
        url = `${url}&_limit=${limit}`;
      }

      if (isTrained) {
        url = `${url}&_is_trained=${isTrained}`;
      }

      return this.http<IPicsetServiceListResponse>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(payload));
    }

    /** Получить список пиксетов
     * @param {string} guid гуид пиксета
     */
    async deletePicset(guid: string): Promise<void> {
      const url = `picset/${guid}`;
      return this.http<void>(url, EHttpMethods.Delete, this.getHeaders());
    }

    /** Создать новый пиксет
     * @param {object} picsetData данные для создания пиксета реализующие интерфейс IPicsetDraft
     */
    async createPicset(picsetData: IBasePayload<IPicsetDraft>): Promise<Record<'guid', string>> {
      const url = 'picset';
      return this.http<Record<'guid', string>>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(picsetData));
    }

    /** Создать новый пиксет из тумбсета
     * @param {object} picsetData данные для создания пиксета реализующие интерфейс IPicsetDraft
     */
    async createPicsetByThumbset(picsetData: IPicsetThumbsetDraft): Promise<Record<'guid', string>> {
      const url = 'picset/video';
      return this.http<Record<'guid', string>>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(picsetData));
    }

    /** Обновить данные пиксета
     * @param {string} guid гуид пиксета
     * @param {object} picsetData данные для обновления пиксета реализующие интерфейс IPicsetUpdateDraft
     */
    updatePicset(guid: string, picsetData: IBasePayload<IPicsetUpdateDraft>): void {
      const url = `picset/${guid}`;
      return this.http<void>(url, EHttpMethods.Put, this.getHeaders(), JSON.stringify(picsetData));
    }

    /** Обновить данные изображений пиксета
     * @param {string} guid гуид пиксета
     * @param {array} ids список id изображений
     */
    async savePicsetPics(guid: string, ids: Record<string, boolean>): Promise<Object> {
      const url = `picset/${guid}/save`;
      const data = { guid, excluded: ids };
      return this.http<Object>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(data));
    }

    /** Установить статус готовности пиксета
     * @param {string} guid гуид пиксета
     * @param {boolean} isReady статус
     */
    async setPicsetReadiness(guid: string, isReady: boolean): Promise<void> {
      const url = `picset/${guid}/readiness`;

      const data = {
        guid,
        isReady,
      };

      return this.http<void>(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(data));
    }

    /** Обновить данные изображений пиксета
     * @param {object} picsetData данные пиксета
     */
    async createPicsetByFile(picsetData: FormData) {
      const url = 'archive';

      return this.http(url, EHttpMethods.Post, this.getHeaders(), picsetData, { endpoint: EConfigStorageKeys.apiEndpointV2 });

      // eslint-disable-next-line no-undef
      // return axios
      //   .post(url, picsetData, {
      //     headers: this.getHeaders() as AxiosHeaders,
      //     cancelToken,
      //   })
      //   .then((x) => x.data)
      //   .catch((thrown) => {
      //     // eslint-disable-next-line no-undef
      //     if (axios.isCancel(thrown)) {
      //       console.log('Request canceled: ', thrown.message);
      //     } else {
      //       return thrown;
      //     }
      //   });
    }

    async validatePicsetGroups(guid: string, groups: Array<string>, mode = true): Promise<void> {
      const url = `picset/${guid}/validated`;
      const payload = {
        guid,
        group: groups,
        validated: mode,
      };
      return this.http(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify(payload));
    }

    async archivePicset(guid: string): Promise<unknown> {
      const url = `picset/${guid}/archive`;
      return this.http(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify({ guid }));
    }

    async disablePicset(guid: string, disabled: boolean): Promise<void> {
      const url = `picset/${guid}/disabled`;
      return this.http(url, EHttpMethods.Post, this.getHeaders(), JSON.stringify({ guid, disabled }));
    }
}
