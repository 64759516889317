import { UserService } from '@/shared/api/services/user';
import { HttpService } from '@/shared/api/services/common/http.service';
import { EHttpMethods } from '@/shared/api/services/common/enums/HttpMethods';
import { ICategoriesResponse } from '@/shared/api/services/categories/models/categoriesService.model';
import { ICategoriesFilter, ICategory, ICategoryDraft } from '@/shared/models/category.model';
import {
  IBasePayload,
  IBaseSingleResponse,
  IBaseSuccessPostResponse,
} from '@/shared/api/services/common/models/http.model';

export class CategoriesService extends HttpService {
    private static token: string;

    private static headers: HeadersInit;

    private static _instance: CategoriesService;

    constructor() {
      super();
      CategoriesService.token = UserService.getToken();
      CategoriesService.headers = {
        Authorization: `bearer ${CategoriesService.token}`,
      };

      if (CategoriesService._instance) {
        return CategoriesService._instance;
      }

      CategoriesService._instance = this;
    }

    /** Получить список категорий с опциональной фильтрацией
     * @param {Object} filter фильтр по группе, объект, реализующий интерфейс ICategoriesFilter
     */
    async getList(filter?: ICategoriesFilter): Promise<ICategoriesResponse> {
      const url = 'category?_order_by=created desc';

      return this.http<ICategoriesResponse>(url, EHttpMethods.Post, CategoriesService.headers, JSON.stringify(filter));
    }

    /** Получить информацию о категории по guid
     * @param {string} guid guid категории
     */
    async getByGuid(guid: string): Promise<IBaseSingleResponse<ICategory>> {
      const url = `category/${guid}`;

      return this.http<IBaseSingleResponse<ICategory>>(url, EHttpMethods.Get, CategoriesService.headers);
    }

    /** Создать категорию
     * @param {Object} payload информация о создаваемой группе в формате ICategoryDraft
     */
    async create(payload: IBasePayload<ICategoryDraft>): Promise<IBaseSuccessPostResponse> {
      const url = 'category';

      return this.http(url, EHttpMethods.Put, CategoriesService.headers, JSON.stringify(payload));
    }

    /** Обновить категорию
     * @param {string} guid guid изменяемой категории
     * @param {Object} payload информация о создаваемой группе в формате ICategoryDraft
     */
    async update(guid: string, payload: IBasePayload<ICategoryDraft>): Promise<IBaseSuccessPostResponse> {
      const url = `category/${guid}`;

      return this.http(url, EHttpMethods.Patch, CategoriesService.headers, JSON.stringify(payload));
    }

    /** Удалить категорию
     * @param {string} guid guid изменяемой категории
     */
    async delete(guid: string): Promise<IBaseSuccessPostResponse> {
      const url = `category/${guid}`;

      return this.http(url, EHttpMethods.Delete, CategoriesService.headers);
    }
}
