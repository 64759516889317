export enum EModalsList {
    CreateUser,
    SetUserPassword,
    EditRoles,
    CreateGroup,
    EditGroup,
    CreateCategory,
    EditCategory,
    Confirm,
    CreatePicset,
    CreatePicsetByThumbset,
}
