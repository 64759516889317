import { ISeriesItem } from '@/shared/models/series.model';
import { ICategory } from '@/shared/models/category.model';
import { pick } from 'lodash';

export class GroupsUtil {
  public static getGroupByCategoryName(categoryName: string, groupsList: Array<ISeriesItem>): ISeriesItem | undefined {
    return groupsList.find((group) => !!group.categories?.find((category) => category.title === categoryName));
  }

  public static getGroupByTitle(groupTitle: string, groupsList: Array<ISeriesItem>): ISeriesItem | undefined {
    return groupsList.find((({ title }) => title === groupTitle));
  }

  public static getGroupByCategoryGuid(guid: string, groupsList: Array<ISeriesItem>): ISeriesItem | undefined {
    return groupsList.find((group) => !!group.categories?.find((category) => category.guid === guid));
  }

  public static getGroupByGuid(guid: string, groupsList: Array<ISeriesItem>): ISeriesItem | undefined {
    return groupsList.find((group) => group.guid === guid);
  }

  public static getAllCategoriesFromGroups(groupsList: Array<ISeriesItem>): Array<string> {
    return groupsList.map(({ categories }) => categories?.map(({ title }) => title) || []).flat();
  }

  public static getGroupCategoriesByGroupName(
    groupName: string,
    groupsList: Array<ISeriesItem>,
    reducer?: string | Array<string>,
  ): Array<string> | Array<ICategory> | Array<Partial<ISeriesItem>> {
    const foundGroup = GroupsUtil.getGroupByTitle(groupName, groupsList);
    if (!foundGroup) {
      return [];
    }

    console.log(reducer);

    if (!reducer) {
      return foundGroup.categories || [];
    }

    if (reducer && !Array.isArray(reducer)) {
      return (foundGroup.categories || []).map((category) => category[reducer]);
    }

    if (reducer && Array.isArray(reducer)) {
      // eslint-disable-next-line max-len
      return (foundGroup.categories || []).map((item) => Object.fromEntries(Object.entries(item).filter(([key]) => reducer.includes(key))));
    }

    return [];
  }
}
