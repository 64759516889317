import { AppPreparingPicsetsListPage } from '@/pages/preparing-list';
import { AppUsersList } from '@/pages/users-list';
import { AppImagesPage } from '@/pages/images/';
import { AppCategoriesPage } from '@/pages/categories';
import { AppPicsetCheckPage } from '@/pages/picset-check';
import { AppPicsetPreparePage } from '@/pages/picset-prepare';
import { AppTrainedListPage, AppErroneousListPage } from '@/pages/trained-list';
import { AppLoginPage } from '@/pages/login';
import { AppDeniedPage } from '@/pages/denied';
import { AppVideosThumbsItemPage, AppVideosThumbsPage } from '@/pages/videos-thumbs';
import { AppErroneousPicsetPage } from '@/pages/erronuos-picset';
import { EPaths } from '@/router/enums/paths.enum';

export const routes = [
  {
    path: '/',
    name: 'root',
    redirect: EPaths.Preparing,
  },
  {
    path: EPaths.Login,
    name: 'login',
    component: AppLoginPage,
  },
  {
    path: EPaths.NotFound,
    name: 'denied',
    component: AppDeniedPage,
  },
  {
    path: EPaths.Preparing,
    name: 'Home',
    component: AppPreparingPicsetsListPage,
  },
  {
    path: `${EPaths.Preparing}/:guid`,
    name: 'Preparing',
    component: AppPicsetPreparePage,
  },
  {
    path: `${EPaths.List}${EPaths.Trained}`,
    name: 'Trained',
    component: AppTrainedListPage,
  },
  {
    path: `${EPaths.Trained}/:guid`,
    name: 'TrainedPicset',
    component: AppPicsetCheckPage,
  },
  {
    path: EPaths.Images,
    name: 'Images',
    component: AppImagesPage,
  },
  {
    path: EPaths.Categories,
    name: 'Categories',
    component: AppCategoriesPage,
  },
  {
    path: EPaths.Users,
    name: 'Users',
    component: AppUsersList,
  },
  {
    path: EPaths.Videos,
    name: 'videos',
    component: AppVideosThumbsPage,
  },
  {
    path: `${EPaths.Videos}/:md5`,
    name: 'videosItem',
    component: AppVideosThumbsItemPage,
  },
  {
    path: `${EPaths.List}${EPaths.Erroneous}`,
    name: 'ErroneousList',
    component: AppErroneousListPage,
  },
  {
    path: `${EPaths.Erroneous}/:guid`,
    name: 'Erroneous',
    component: AppErroneousPicsetPage,
  },
];
