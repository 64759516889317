import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/routes/routes';
import {
  authorizationHook, loginHook, permissionsHook, useGuards,
} from '@/router/hooks/hooks';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(useGuards([authorizationHook, loginHook, permissionsHook]));

export default router;
