import { UserService } from '@/shared/api/services/user/';
import { PicsetService } from '@/shared/api/services/picset';
import { SeriesService } from '@/shared/api/services/series';
import { CategoriesService } from '@/shared/api/services/categories';
import { RolesService } from '@/shared/api/services/roles';
import { PicturesService } from '@/shared/api/services/pictures';
import { NeuropassService } from '@/shared/api/services/neuropass';

export class ApiServices {
    static UserService = UserService;

    static PicsetService = PicsetService;

    static SeriesService = SeriesService;

    static CategoriesService = CategoriesService;

    static RolesService = RolesService;

    static PicturesService = PicturesService;

    static NeuropassService = NeuropassService;
}
