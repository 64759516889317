import { EActions } from '@/shared/enums/actions';

export const RoleActions: Map<string, Array<EActions>> = new Map([
  [
    'admin',
    [EActions.God],
  ],
  [
    'review',
    [
      EActions.ViewPreparing,
      EActions.ViewTrained,
      EActions.ViewImages,
      EActions.CreatePicset,
      EActions.ViewSelfPreparingPicsets,
      EActions.SavePicset,
      EActions.SetValidatedGroup,
      EActions.SetPicsetPictures,
      EActions.SetPicsetReadiness,
      EActions.SetTrainedCategory,
    ],
  ],
]);
